<template>
  <base-section
    class="py-5 accent px-5"
    id="info-nanotech"
  >
    <template v-for="({ text, title: t }, i) in info">
      <v-container
        class="white--text"
         :key="i"
      >
        <v-row
         class="text-h6"
        >
          {{t}}
        </v-row>
        <v-row>
          {{text}}
        </v-row>
    </v-container>
    </template>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionInfoNanotech',
    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
      countury: String,
    },

    data: () => ({
      info: [
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '+60 12-329-8289',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'nanotech6393@hotmail.com',
        },
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: 'No 20, Lorong CP8/23, Cheras Perdana, Cheras, 43200, Selangor, Malaysia',
        },
      ],
    }),
  }
</script>

<style lang="sass">
  #info-alt a
    text-decoration: none
</style>
